import React from "react"
import tw from "twin.macro"

export const Content = tw.div`
  text-center
  text-primary-600
  mx-auto pt-20 lg:pt-16`

export const Text = tw.div`
  md:col-count-2
  md:col-gap-6
  md:text-left
  text-gray-700
  font-karla
  px-20
  pt-16 pb-4`

const Emotion = tw.div`
text-3xl
font-emotion
  `

const Header = tw.div`
  text-4xl
  text-gray-800  `

export const TwoLines = tw.div`
  border-t-2 border-b-2 w-20 border-primary-600
  mx-auto mt-4
  min-h-1
`

const EmotionHeader = ({ title, subTitle }) => {
  return (
    <>
      <Emotion>{title}</Emotion>
      <Header>{subTitle}</Header>
      <TwoLines></TwoLines>
    </>
  )
}

export default EmotionHeader
